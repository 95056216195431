import { gql } from 'graphql-request';

export const ABOUT_FRAGMENT = gql`
  fragment aboutBlock on AboutBlock {
    id
    contentType
    enabled
    data {
      headline
      description
      image {
        ...imageVariant
      }
    }
  }
`;

export const IMPACT_FACTS_FRAGMENT = gql`
  fragment impactFactsBlock on ImpactFactsBlock {
    contentType
    id
    enabled
    data {
      headline
      description
      image {
        ...extendedImageType
      }
      impactFacts {
        title
        description
        sdgs
      }
    }
  }
`;

export const PRODUCTS_BLOCK_FRAGMENT = gql`
  fragment productsBlock on ProductsBlock {
    id
    contentType
    enabled
    data {
      moreProducts {
        title
        description
        link
        image {
          ...imageType
        }
      }
    }
  }
`;

export const MORE_INFO_BLOCK_FRAGMENT = gql`
  fragment moreInfoBlock on MoreInfoBlock {
    id
    contentType
    enabled
    data {
      blockTitle
      headline
      description
      mediaItem {
        ... on ImageVariant {
          __typename
          id
          imageId
          url
          crop {
            x
            y
            width
            height
          }
        }
        ... on Video {
          vimeoId
          vimeoLink
          __typename
        }
      }
    }
  }
`;

export const FEEDBACK_BLOCK_FRAGMENT = gql`
  fragment feedbackBlock on FeedbackBlock {
    id
    contentType
    enabled
    data {
      ratingEnabled
      headline
      subtitle
    }
  }
`;

export const COMPANY_DESIGN_FRAGMENT = gql`
  fragment companyDesign on CompanyDesign {
    palette {
      primary
    }
  }
`;

export const VERIFIED_PAYMENTS_BLOCK_FRAGMENT = gql`
  fragment VerifiedPaymentsBlockValues on VerifiedPaymentsBlock {
    id
    contentType
    data {
      headline
      description
      image {
        ...imageVariant
      }
    }
  }
`;
